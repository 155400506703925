
























import CategoryPropertyList from '@/components/CategoryPropertyList.vue'
import { PropertyCategoryId } from '@/services/modules/property'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Rentals',

  components: {
    CategoryPropertyList,
  },

  props: {
    startingDate: {
      type: String,
      default: '',
    },
    endingDate: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      categoryId: PropertyCategoryId.Rentals,
    }
  },
})
